.events {
  margin: 0;
  padding: 0;
  list-style: none;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}

.notes-month section {
  font-size: 28px;
}

.fc-direction-ltr .fc-daygrid-event.fc-event-end, .fc-direction-rtl .fc-daygrid-event.fc-event-start{
  border: none;
  border-radius: 4px;
}

.li-event {
  padding: 4px 8px;
  list-style: none;
}

.li-event-red {
  background-color: #ff7875;
  border-color: #ff7875;
  border-radius: 4px;
}

.li-event-blue {
  background-color: #40a9ff;
  border-color: #40a9ff;
  border-radius: 4px;
}

.filter-calendar{
  text-align: right;
}