.bg-camera {
    height: 100%;
    position: fixed;
    top: 0;
    background: #0000005c;
    width: 100%;
    left: 0;
    z-index: +999999;
    text-align: center;
}

.content-camera {
    margin: auto;
    border: 8px solid #00000031;
    /* position: relative; */
    text-align: center;
}

.btn-camera {
    z-index: +999999999999;
    background-color: #0000003a;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    height: auto;
    position: sticky;
    top: 50%;
    left: 50%;
    border-radius: 4px;
}

.btn-take-photo {
    z-index: +999999999999;
    background-color: #ffffff93;
    border: 16px solid #fffefe98;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
    margin: 8px;
}

.icon-change-camera-native {
    z-index: +999999999;
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    margin: 4px;
}

.icon-close-camera-native {
    z-index: +999999999;
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    margin: 4px;
}


/* Media Queries untuk responsivitas */
@media (max-width: 768px) {
    .content-camera {
        margin-top: 0px;
    }

    .btn-camera {
        width: 100%;
        transform: none;
    }
}


@media (min-width: 768px) {
    .content-camera {
        margin-top: 60px;
        max-width: 50%;
    }

    .btn-camera {
        width: 200px;
        transform: translate(-50%, -50%);
    }
}