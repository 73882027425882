.ql-editor {
    min-height: 300px !important;
}

.ql-toolbar.ql-snow {
    border: 0 !important;
}

.ql-container.ql-snow {
    border: 0;
    border-radius: 4px;
}

.ant-picker {
    border: 1px solid #c3c2c2 !important;
    border-radius: 4px !important;
    padding: 6px 11px 6px !important;
}

.bg-camera {
    height: 100%;
    position: fixed;
    top: 0;
    background: #0000005c;
    width: 100%;
    left: 0;
    z-index: +999;
    text-align: right;
}

.content-camera {
    margin: auto;
    margin-top: 60px;
    width: 50%;
    border: 8px solid #00000031;
    position: relative;
}
.content-camera-absensi {
    margin: auto;
    margin-top: 8px;
    width: 50%;
    border: 8px solid #00000031;
    position: relative;
}

.icon-change-camera {
    z-index: +9999;
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 24px;
    right: 40px;
    transform: translate(-50%, -50%);
}

.icon-close-camera {
    z-index: +9999;
    background-color: #fff;
    padding: 4px;
    border-radius: 4px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.08);
    position: absolute;
    top: 24px;
    right: 8px;
    transform: translate(-50%, -50%);
}

.react-html5-camera-photo {
    margin-bottom: -5px !important;
}