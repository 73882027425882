.input-row {
    margin-bottom: 8px;
}

.input-box {
    width: 100%;
    text-align: center;
}

.grade-table .ant-table-thead>tr>th {
    background-color: #d9d9d9;
    text-align: center;
}

.grade-table .ant-table-tbody>tr>td {
    text-align: center;
}

.grade-table .ant-table-tbody>tr:nth-child(odd)>td {
    background-color: #f5f5f5;
}