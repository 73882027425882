.ql-editor {
    min-height: 300px !important;
}

.ql-toolbar.ql-snow {
    border: 0 !important;
}

.ql-container.ql-snow{
    border: 0;
    border-radius: 4px;
}

.ant-picker {
    border: 1px solid #c3c2c2 !important;
    border-radius: 4px !important;
    padding: 6px 11px 6px !important;
}

.extra-full-width .ant-form-item-extra {
    width: 300px;
}