#print {
    position: relative;
    height: 290px;
    width: 100%;
    padding: 20px;
    /* border: 1px solid #ddd; */
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;
}

#print h3 {
    text-align: center;
}

.logoman {
    grid-area: logoman;
}

.logoman>img {
    width: 36px;
}

.titleman {
    grid-area: titleman;
}

.logoikaman {
    grid-area: logoikaman;
}

.logoikaman>img {
    width: 36px;
}

.grid-container-kta {
    display: grid;
    grid-template-areas:
        'logoman titleman titleman titleman titleman logoikaman'
        'logoman titleman titleman titleman titleman logoikaman'
        'logoman titleman titleman titleman titleman logoikaman';
    gap: 8px;
}

.grid-container-kta>div {
    text-align: center;
    line-height: 1.5em;
}