.ant-image-mask,
.ant-image-mask:hover {
    border-radius: 100px !important;
}

.resp-rrror {
    color: red;
    max-height: 400px;
    overflow-y: auto;
}

.div-loader {
    background-color: #ffffffc4;
    width: 100%;
    min-height: 100%;
    padding: 5px;
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: +9;
}

.loader {
    border: 16px solid #f3f3f3;
    /* Light grey */
    border-top: 16px solid #3498db;
    /* Blue */
    border-radius: 50%;
    width: 120px;
    height: 120px;
    animation: spin 2s linear infinite;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}