.ant-menu-inline,
.ant-menu-vertical,
.ant-menu-vertical-left {
    border-right: 0px !important;
}

.ant-drawer-body {
    padding: 0 !important;
}

.ant-drawer-close {
    z-index: +999;
    padding: 40px 8px 8px 8px;
    background: #fff;
    border-radius: 24px;
    font-weight: bold;
}

.ant-drawer-header-close-only {
    padding: 0;
}