.layout {
    display: flex;
    height: 100vh;
    overflow: hidden;
    font-family: 'Work Sans';
    font-style: normal;
  }
  
  .left-column {
    flex: 1;
    background-image: url('../../assets/images/login-img.jpg');
    background-size: cover;
    background-position: center;
  }
  
  .right-column {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column; /* Tambahkan ini */
  }
  
  .header-content {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .login-logo {
    width: 80px;
    margin-bottom: 10px;
  }
  
  .login-title {
    font-weight: bold;
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 30px;
    line-height: 1.5em;
  }
  
  .login-description {
    color: #666;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
  
  .login-footer {
    font-weight: 400;
    font-size: 12px;
    color: #12784A;
    line-height: 24px;
  }
  
  .login-container {
    width: 300px;
    padding: 38px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    text-align: left;
    margin: 16px 0;
  }

  .login-container label {
    font-weight: bold;
  }

  .login-container .ant-form-item-row{
    margin-bottom: 16px;
  }

  .login-container button{
    padding: 16px 24px !important;
  }
  
  /* Media query untuk tampilan mobile */
  @media (max-width: 768px) {
    .layout {
      flex-direction: column;
    }
  
    .left-column {
      display: none;
    }
  
    .right-column {
      flex: none;
      width: 100%;
      height: 100vh;
      padding: 0 20px;
      overflow: hidden;
    }
  
    body, html {
      overflow-x: hidden;
    }
  }
  