.react-html5-camera-photo>img, .react-html5-camera-photo>video {
    width: 100% !important;
}

.btn-close {
    margin: 0 auto;
    position: relative;
    background: white;
    color: red;
    padding: 0px;
    border-color: white;
    border-radius: 100% !important;
}

.dashboard-column {
    background-color: #fff;
    padding: 24px;
    border-radius: 8px;
    border-left: 2px solid #10B582;
}

.dashboard-column-icon {
    border-radius: 8px !important;
    margin-top: 8px;
}

.dashboard-column .ant-image-img {
    width: 50px;
    height: 50px;
    object-fit: cover;    
}

.skeleton-absensi, .skeleton-absensi .ant-skeleton-input {
    width: 100%;
    margin: 0 0 4px 0px;
    border-radius: 8px;
    min-height: 100px;
}


@media only screen and (max-width: 600px) {
    .dashboard-column .ant-image-img {
        object-fit: cover;    
    }
}

.coordinate-info{
    text-align: left;
    background: #f0f0f0;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 4px 10px;
}

.content-camera-absensi {
    width: 80% !important;
}

.absensi-modal .ant-modal-content{
    padding: 20px 0 !important;
}